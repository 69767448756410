<template>
  <div id="app">
    <footer class="footer">
      <div class="footer-info">
        <div class="footer-info-l">
          <div class="l_top">
            <span>联系我们</span>
            <el-button class="btn" type="primary" size="normal" @click="link">立即联系</el-button>
          </div>
          <div class="l_center">
            如需了解详情，您可联系我们，我们的业务顾问将为您提供更多信息和资料
          </div>
          <ul class="address">
            <li @click="phoneClick('0512-6856-5993')">联系电话：0512-6856-5993</li>
            <li style="cursor: pointer;"  @click="contactUs()">联系邮箱：fubian@fubianmed.com</li>
            <li style="display:flex"><span style="white-space: nowrap;">公司地址：</span><p>江苏省苏州市若水路388号苏州纳米科技园E栋602室</p></li>
          </ul>
        </div>
        <div class="footer-info-r">
          <img src="@/assets/image/index/erweima.png" alt="erweima">
          <div class="about">关注我们</div>
        </div>
      </div>
    </footer>
    <div class="footer-copy">
      <div>© 2020-2021 苏州复变医疗科技有限公司. 版权所有｜<a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备20011208号-1</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fhFooter',
  props: {
    isBlue: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    contactUs () {
      window.location.href = 'mailto:fubian@fubianmed.com'
    },
    phoneClick (phoneNum) {
      window.location.href = 'tel:' + phoneNum
    },
    link () {
      window.location.href = '/contact'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.footer {
  width: 100%;
  color: #fff;
  background: #242A37;
  height: 334px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-info {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-info-l{
  font-size: 14px;
  color:rgba(255,255,255,0.8);
  .l_top{
    display: flex;
    align-items: center;
    span{
      font-size: 24px;
      color: #fff;
      line-height: 1;
    }
    .btn{
      width: 68px;
      height: 24px;
      font-size: 12px;
      border-radius: 2px;
      margin-left: 14px;
      background: #fff;
      border-color: #fff;
      color: #2775FF;
    }
    .btn:hover{
      background: #2775FF;
      border-color: #2775FF;
      color: #fff;
    }
  }
  .l_center{
    margin: 20px 0 32px 0;
  }
  li p{
    display: inline-block;
    margin: 0;
  }
  li:nth-child(2){
    margin: 15px 0;
  }

}

.footer-info-r {
  img{
    width: 144px;
    height: 144px;
  }
  .about{
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 30px;
    opacity: 0.8;
    margin-top: 16px;
  }
}
.footer-copy {
  width: 100%;
  background: #181E2A;
  height: 70px;
  line-height: 70px;
  div {
    max-width: 1200px;
    font-size: 12px;
    color: rgba(255,255,255,0.6);
    text-align: center;
    margin: 0 auto;
  }
  a {
    color: rgba(255,255,255,0.6);
  }
}
@media (max-width: 768px) {
  .app{
    padding-bottom: 1.25rem;
  }
  .footer{
    height: auto;
    padding: 0 14px;
    padding-bottom:1.75rem;
    .footer-info{
      flex-direction: column;
      .footer-info-l{
        .l_top{
          display: flex;
          align-items: center;
          margin-top: 2.5rem;
          span{
            font-size: 1.125rem;
          }
          .btn{
            width: 4.25rem;
            height: 1.5rem;
            font-size: 0.75rem;
            border-radius: 2px;
            margin-left: 0.875rem;
          }
        }
        .l_center{
          margin: 0.75rem 0 1.75rem 0;
        }
        li:nth-child(2){
          margin: 0.875rem 0;
        }

      }

      .footer-info-r {
        margin-top: 1.75rem;
        img{
          width: 6.25rem;
          height: 6.25rem;
        }
        .about{
          font-size: 0.875rem;
          color: #FFFFFF;
          letter-spacing: 0.9375rem;
          opacity: 0.8;
          margin-top: 1rem;
        }
      }
    }
  }
  .footer-copy {
    height: 3.125rem;
    line-height: 3.125rem;
  }
}
</style>
