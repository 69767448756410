<template>
  <div>
    <div class="main-nav">
      <div class="nav-content">
        <nav ref="sliderMenu">
          <a class="navLabel"
          v-for="(item, index) in tabs" :key="item"
          :class="[current === index && scollIndex === index ? 'tabs-active' : '']"
          @click="scrollToView(index)">
            <div>{{item}}</div>
            <div v-if="current === index && scollIndex === index" class="activeLine">
              <span class="sanjiaoxing"></span>
              <span class="line"></span>
            </div>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav',
  props: {
    tabs: {
      type: Array
    },
    current: {
      type: Number
    },
    scollIndex: {
      type: Number
    }
  },
  methods: {
    scrollToView (index) {
      this.$nextTick(() => {
        this.current = index
        document.getElementById(`nav${index + 1}`).scrollIntoView({
          behavior: 'smooth', // 平滑过渡
          block: 'center' // 上边框与视窗顶部平齐。默认值
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/main.less";

</style>
