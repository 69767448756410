<template>
  <div>
    <header v-if="!mobile" class="header" :class="{'white-bg': visible || whiteBg}">
      <nav class="navbar navbar-light"   @mouseover="navOver" @mouseleave="navOut">
        <a class="navbar-brand logo-box" href="/index">
          <IconFont
            name="#fbIndex-fubianlogo"
            :width="38"
            :height="38"
            :color="'#2775FF'"
            v-if='whiteBg || visible'
          ></IconFont>
          <!-- <img  src="@/assets/image/fubian-logo.svg" height="36" class="d-inline-block align-top " alt="logo" > -->
          <IconFont
            name="#fbIndex-fubianlogo"
            :width="38"
            :height="38"
            :color="'#FFFFFF'"
            v-else
          ></IconFont>
          <div class="logo-box-r">
            <p class="mb-0">复变医疗科技</p>
            <p class="mb-0">FUBIAN MEDICAL TECH</p>
          </div>
        </a>
        <div class="menu">
          <div v-for="(item,index) in tabs" :key="item"
          class="nav-item"
          :class="[tabIndex === index ? 'tabs-active' : '']"
          @click="toLink(index)"
          @mouseover="handleIn(index)"
          @mouseleave="handleOut">{{item}}
            <div v-if="tabIndex === index" class="activeLine">
                <span class="sanjiaoxing"></span>
                <span class="line"></span>
            </div>
          </div>
        </div>
      </nav>
      <div class="productDiv"  @mouseover="handleIn(1)" @mouseleave="handleOutDiv">
        <div class="divBox">
          <div class="projectBox" @click="tiaozhuan('/yunyu')">
            <img src="@/assets/image/index/yunyu.png" alt="yunyu">
            <div class="boxTitle">复变云愈</div>
            <div class="boxTips">心理测评疏导系统</div>
          </div>
          <div class="projectBox"  @click="tiaozhuan('/knee')">
            <img src="@/assets/image/index/kj.png" alt="kj">
            <div class="boxTitle">复变智识<br>膝关节辅诊</div>
            <div class="boxTips">全膝关节置换术软组织平衡辅助决策系统</div>
          </div>
          <div class="projectBox" @click="tiaozhuan('/alzheimer')">
            <img src="@/assets/image/index/alz.png" alt="alz">
            <div class="boxTitle">复变念念</div>
            <div class="boxTips">阿尔茨海默病辅助筛查系统</div>
          </div>
          <div class="projectBox" @click="tiaozhuan('/inspection')">
            <img src="@/assets/image/index/zong.png" alt="zong">
            <div class="boxTitle">智能总检</div>
            <div class="boxTips">总检智能分析系统</div>
          </div>
        </div>
      </div>
    </header>
    <header v-else class="header" :class="{'white-bg': whiteBg}">
      <nav class="navbar navbar-light"   @mouseover="navOver" @mouseleave="navOut">
        <a class="navbar-brand logo-box" href="/index">
          <IconFont
            name="#fbIndex-fubianlogo"
            :width="30"
            :height="30"
            :color="'#2775FF'"
            v-if='whiteBg'
          ></IconFont>
          <!-- <img  src="@/assets/image/fubian-logo.svg" height="36" class="d-inline-block align-top " alt="logo" > -->
          <IconFont
            name="#fbIndex-fubianlogo"
            :width="30"
            :height="30"
            :color="'#FFFFFF'"
            v-else
          ></IconFont>
          <div class="logo-box-r">
            <p class="mb-0">复变医疗科技</p>
            <p class="mb-0">FUBIAN MEDICAL TECH</p>
          </div>
        </a>
        <img v-if="whiteBg" @click="drawer = true"   src="@/assets/image/app/line2.png" alt="line2">
        <img v-else @click="drawer = true"  src="@/assets/image/app/line.png" alt="line">
      </nav>
      <el-drawer
        title=""
        :visible.sync="drawer"
        size="50%">
        <div class="drawer_box">
          <div class="title"  :class="{'changeColor': pathname === '/index' || pathname === '/index/'}" @click="tiaozhuan('/index')">首页</div>
          <div class="title">
            <span :class="{'changeColor': pathname === '/yunyu' || pathname === '/yunyu/'}" @click="tiaozhuan('/yunyu')">复变云愈</span>
            <span :class="{'changeColor': pathname === '/knee' || pathname === '/knee/'}" @click="tiaozhuan('/knee')">复变智识膝关节辅诊</span>
            <span :class="{'changeColor': pathname === '/alzheimer' || pathname === '/alzheimer/'}" @click="tiaozhuan('/alzheimer')">复变念念</span>
            <span :class="{'changeColor': pathname === '/inspection' || pathname === '/inspection/'}" @click="tiaozhuan('/inspection')">智能总检</span>
          </div>
          <div class="title" :class="{'changeColor': pathname === '/about' || pathname === '/about/'}" @click="tiaozhuan('/about')">关于我们</div>
        </div>
      </el-drawer>
    </header>
  </div>
</template>

<script>
import IconFont from './IconFont/IconFont.vue'
export default {
  name: 'fhHeader',
  props: {
    whiteBg: {
      type: Boolean
    },
    tabIndex: {
      type: Number
    }
  },
  components: {
    IconFont
  },
  data () {
    return {
      pathname: '/index',
      visible: false,
      tabs: ['首页', '产品服务', '关于我们'],
      isShow: false,
      mobile: '',
      drawer: false
    }
  },
  mounted () {
    this.mobile = window.matchMedia('(max-width: 768px)').matches
    this.pathname = window.location.pathname || '/index'
  },
  methods: {
    toLink (i) {
      this.tabIndex = i
      if (i === 0) {
        window.location.href = '/index'
      } else if (i === 1) {
        if (this.pathname === '/index' || this.pathname === '/index/') {
          this.tabIndex = 0
        } else if (this.pathname === '/about' || this.pathname === '/about/') {
          this.tabIndex = 2
        } else if (this.pathname === '/contact' || this.pathname === '/contact/') {
          this.tabIndex = 3
        }
      } else if (i === 2) {
        window.location.href = '/about'
      }
    },
    navOver () {
      this.visible = true
    },
    navOut () {
      this.visible = false
    },
    handleIn (i) {
      const style = document.querySelector('.productDiv')
      const styleBox = document.querySelector('.divBox')
      if (i === 1) {
        this.visible = true
        style.style.height = '300px'
        style.style.padding = '50px 0'
        styleBox.style.height = '224px'
      } else {
        style.style.height = 0
        style.style.padding = 0
        styleBox.style.height = 0
      }
    },
    handleOut () {
      this.mouseOut()
    },
    handleOutDiv () {
      this.mouseOut()
      setTimeout(() => {
        this.visible = false
      }, 300)
    },
    mouseOut () {
      const style = document.querySelector('.productDiv')
      const styleBox = document.querySelector('.divBox')
      style.style.height = 0
      style.style.padding = 0
      styleBox.style.height = 0
    },
    tiaozhuan (path) {
      window.location.href = path
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../assets/css/main.less";
.header {
  position: fixed;
  width: 100%;
  background-color: transparent;
  z-index: 999;
  .navbar {
    position: relative;
    height: @header-h;
    padding: 0;
    padding-right: 1rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    a {
      color: #fff;
    }
  }
  .logo-box {
    display: flex;
    align-items: center;
    color: #fff;
    .logo-box-r {
      display: inline-block;
      margin-left: .5rem;
      p {
        line-height: 0.8;
        margin-top: 5px;
        font-size: 20px;
        &:last-child {
          transform: scale(.56);
          transform-origin: 0;
        }
      }
    }
  }
  .menu {
    display: flex;
    padding: 0;
    height: 100%;
    align-items: center;
    .nav-item{
      color: rgba(255,255,255,0.6);
      position: relative;
      width: 64px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nav-item:nth-child(2) {
      margin: 0 72px;
    }
    .tabs-active{
      color: rgba(255,255,255,1);
    }
  }
}
.white-bg {
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  animation: bg 0s ease 1;
  a {
    color: @main-black;
  }
  .menu{
    .nav-item{
      color: @main-black;
    }
    .nav-item:hover {
      color: #2775FF
    }
    .tabs-active{
      color: #2775FF;
    }
    .activeLine{
        position: absolute;
        top: 0;
        left: 0;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        height: 70px;
        display: flex;
        .sanjiaoxing{
            width: 0;
            height: 0;
            border-width: 0 6px 6px;
            border-style: solid;
            border-color: transparent transparent #2775FF;
            display: inline-block;
        }
        .line{
          width: 68px;
          height: 2px;
          display: inline-block;
          background: #2775FF;
        }
      }
  }
  .logo-box-r{
    color: @main-black-02;
  }
}
@keyframes bg {
  0% { background-color: transparent; }
  100% { background-color: #fff; }
}
.productDiv{
  width: 100vw;
  height: 0;
  left: 0 !important;
  top: 70px !important;
  z-index: 999;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  padding: 0;
  opacity: 1;
  background: #fff;
  position: absolute;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
}
.divBox{
  max-width: 1200px;
  width:100%;
  display:flex;
  opacity: 1;
  transition: all 0.3s;
  height: 0;
  overflow:hidden;
}
.projectBox{
  width: 172px;
  height: 224px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  padding: 20px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  img{
    width: 64px;
    height: 64px;
  }
  .boxTitle{
    height: 18px;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .boxTips{
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    border-top: 1px solid #EBEBEB;
    padding-top: 20px;
  }
}
.disabled{
  pointer-events: none;
  background: #f5f5f5;
}
.projectBox:hover{
  background: #E8F0FF;
  border: 1px solid #2775FF;
}
@media (max-width: 768px) {
  /deep/.el-drawer__header{
    padding: 0;
    color: #2775FF;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    .el-drawer__close-btn{
      font-size: 1.375rem;
    }
  }
  .header {
    .navbar{
      height: 3.25rem;
      padding: 0 1rem;
      .logo-box {
        p{
          font-size: 1rem;
        }
      }
      img{
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .white-bg{
      box-shadow: 0px 0.1875rem 0.625rem rgba(0, 0, 0, 0.12);
    }
  }
  .changeColor{
    color: #2775FF !important;
  }
  .drawer_box{
    font-size: 0.875rem;
    .title{
      padding: 1rem;
      color: #333;
    }
    .title:first-child{
      padding-top: 0;
    }
    .title:nth-child(2){
      border-top: 1px solid #EEEEEE;
      border-bottom: 1px solid #EEEEEE;
    }
    span{
      display: block;
      height: 1.25rem;
      line-height: 1.25rem;
      color: #555;
    }
    span:not(:last-child){
      margin-bottom: 0.875rem;
    }
  }
  .menu {
    display: none;
  }
}
</style>
<style>
.v-modal{
    z-index: 0 !important;
}
</style>
