<template>
   <div id="nav">
      <fbHeader :whiteBg="whiteBg" :tabIndex="1" class="navFix nav1"></fbHeader>
      <div class="main-nav navFix nav2">
        <div class="nav-content">
          <div class="nav-logo" @click="toTop" style="cursor:pointer;" v-if="logo">
            <img src="@/assets/image/product/logo.png" alt="logo">
            <div class="nav-title">
              <div class="cn">复变云愈</div>
              <div class="en">AI.PSYCHOLOGY</div>
            </div>
          </div>
          <div class="productName" @click="toTop" style="cursor:pointer;"  v-else>{{productName}}</div>
          <nav>
            <a class="navLabel"
            v-for="(item, index) in tabs" :key="item"
            :class="[current === index && scollIndex === index ? 'tabs-active' : '']"
            @click="scrollToView(index)">
              <div>{{item}}</div>
              <div v-if="current === index && scollIndex === index" class="activeLine">
                <span class="sanjiaoxing"></span>
                <span class="line"></span>
              </div>
            </a>
          </nav>
          <div class="lianxi" @click="lianxi">
            联系我们
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import fbHeader from '@/components/fbHeader'
export default {
  name: 'App',
  components: {
    fbHeader
  },
  props: {
    whiteBg: {
      type: Boolean,
      default: false
    },
    logo: {
      type: Boolean,
      default: false
    },
    productName: {
      type: String
    },
    tabs: {
      type: Array
    },
    current: {
      type: Number
    },
    scollIndex: {
      type: Number
    }
  },
  methods: {
    scrollToView (index) {
      this.$nextTick(() => {
        this.current = index
        document.getElementById(`nav${index + 1}`).scrollIntoView({
          behavior: 'smooth', // 平滑过渡
          block: 'center' // 上边框与视窗顶部平齐。默认值
        })
      })
    },
    toTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
    lianxi () {
      window.location.href = '/contact'
    }
  }

}
</script>

<style lang="less" scoped>
@import "../assets/css/main.less";

</style>
