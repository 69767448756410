<template>
  <div>
      <div class="box" :style="{'height': height}">
          <div class="box-title">
              <div class="icont">
                <icon-font
                    class="mr-2"
                    :name="icon"
                    :width="mobile ? 30 :48"
                    :height="mobile ? 30 :48"
                    color="#2775FF"
                  ></icon-font>
              </div>
              <div class="title">{{title}}</div>
          </div>
          <div class="box-tips">{{tips}}</div>
      </div>
  </div>
</template>

<script>
import IconFont from './IconFont/IconFont.vue'
export default {
  name: 'productFun',
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    tips: {
      type: String
    },
    height: {
      type: String
    }
  },
  components: {
    IconFont
  },
  data () {
    return {
      mobile: ''
    }
  },
  mounted () {
    this.mobile = window.matchMedia('(max-width: 768px)').matches
  }
}
</script>

<style lang="less" scoped>
  .box{
      width: 390px;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      padding: 24px;
      .box-title{
        display: flex;
        align-items: center;
        .icont{
          width: 48px;
          height: 48px;
          background: #F4F8FF;
          opacity: 1;
          border-radius: 24px;
        }
        .title{
          font-weight: bold;
          font-size: 18px;
          color: #333333;
          margin-left: 16px;
        }
      }
      .box-tips{
        font-size: 14px;
        line-height: 24px;
        color: #888888;
        margin-top: 16px;
      }
    }
@media (max-width: 768px) {
  .box{
    width: 21.4375rem;
    padding: 1.5rem;
    .box-title{
        .icont{
        width: 1.875rem;
        height: 1.875rem;
        .title{
          font-size: 1rem;
          margin-left: 0.75rem;
        }
      }
      .box-tips{
        font-size: 0.875rem;
        line-height: 1.5rem;
        margin-top: 0.75rem;
      }
    }
  }
}
</style>
